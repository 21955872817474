import React, { useState, useEffect } from 'react';
import { Box, VStack, Heading, Text, List, ListItem, Spinner, Button } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { getLibraryQuestions } from '../firebase.utils';
import SEO from './SEO';

const Library = ({ country }) => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false); // Initially set to false to prevent automatic fetching
  const [lastDoc, setLastDoc] = useState(null); // Keep track of the last document for pagination
  const [hasMore, setHasMore] = useState(true); // Assuming more data is available initially

  // Removed auto-fetch on useEffect to prevent fetch on initial render
  // Removed unnecessary useEffect monitoring 'loading'

  const fetchLibraryForms = async () => {
    // Prevent duplicate fetches
    if (loading) return; 
    setLoading(true); // Trigger loading state

    try {
      const response = await getLibraryQuestions(country, lastDoc);
      console.log(response.data);
      if (!response.data || response.data.length === 0) {
        setHasMore(false); // No more data to fetch
      } else {
        setForms((prev) => [...prev, ...response.data]); // Append new data
        setLastDoc(response.lastDoc); // Update lastDoc for pagination
        if (response.data.length < 10) { // Assuming pageSize is 10
          setHasMore(false); // Less data than pageSize indicates no more data
        }
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    } finally {
      setLoading(false); // Ensure loading is disabled after fetch
    }
  };

  useEffect(() => {
    // Initial fetch on component mount
    fetchLibraryForms();
  }, []); // Depend on `country` to refetch when it changes

  return (
    <Box p={5} mb={100} mt={50}>
      <SEO title="TrabajoSeguro AI | Biblioteca de preguntas" />
      <VStack spacing={4} align="stretch">
        <Heading as="h2" mb={4}>Lista de preguntas</Heading>
        
        {forms.length > 0 && (
          <List spacing={3}>
            {forms.map((form, index) => (
              <ListItem key={form.id} borderWidth="1px" borderRadius="md" p={2}>
                <RouterLink to={`/question/${form.id}`}>
                  <Text fontWeight="semibold">{form.question}</Text>
                </RouterLink>
              </ListItem>
            ))}
          </List>
        )}

        {loading && (
          <Spinner textAlign="center" my={4} size="xl" />
        )}

        {!loading && hasMore && (
          <Button onClick={fetchLibraryForms} colorScheme="blue">Cargar más</Button>
        )}

        {!hasMore && (
          <Text textAlign="center">No hay más preguntas disponibles.</Text>
        )}
      </VStack>
    </Box>
  );
};

export default Library;