import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, imageUrl, country }) => {
  // Define default values for title, description, imageUrl, and country
  const defaultTitle = "Trabaja Seguro Gurú";
  const defaultDescription = "Trabaja Seguro Gurú, con la inteligencia artificial y el modelo GPT-4 Turbo, está entrenado para responder preguntas de legislación y consejos de seguridad.";
  const defaultImageUrl = "https://trabajoseguro.guru/share-img.png";

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "Organization",
    "name": `${title || defaultTitle}`,
    "url": "https://trabajoseguro.guru",
    "logo": "https://trabajoseguro.guru/logo.png",
    "description": description || defaultDescription,
    "address": {
      "@type": "PostalAddress",
      "addressCountry": country === "chile" ? "CL" : country === "mexico" ? "MX" : "Unspecified"
    }
  };

  return (
    <Helmet>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={imageUrl || defaultImageUrl} />
    <meta name="twitter:description" content={description || defaultDescription} />
    <meta property="og:url" content="https://trabajoseguro.guru" />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;