// components/MessageList.js
import React, { useRef, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  Box,
  Text,
  HStack,
  Input,
  Button,
} from '@chakra-ui/react';
//import auth
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, doc, updateDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAtpAddq0ybikyg0iXYi4NyUKPjRAe_qHA",
    authDomain: "safety-bot-fd512.firebaseapp.com",
    projectId: "safety-bot-fd512",
    storageBucket: "safety-bot-fd512.appspot.com",
    messagingSenderId: "758788132894",
    appId: "1:758788132894:web:5b4005275b519a30f49e0f",
    measurementId: "G-PWFXV55TFH"
  };
  // Firebase initialization
  const app = initializeApp(firebaseConfig);
  const firestore = getFirestore(app);
  const auth = getAuth();

// Feedback component.
const Feedback = ({ threadID, lastQuestion, lastResponse }) => {
    const [feedbackText, setFeedbackText] = useState('');
    const [feedbackDocId, setFeedbackDocId] = useState(null);
    const [selectedEmoji, setSelectedEmoji] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        // Reset feedback state when threadID changes
        setFeedbackText('');
        setFeedbackDocId(null);
        setSelectedEmoji(null);
        setSubmitted(false);
      }, [threadID]);
  

  const emojis = {
    bad: '😞',
    neutral: '😐',
    good: '😄'
  };

  const handleEmojiClick = async (emojiKey) => {
    setSelectedEmoji(emojiKey);
    if (!submitted) {
      const feedbackData = {
        uuid: uuidv4(),
        threadId: threadID,
        userName: auth.currentUser?.displayName || 'Anonymous',
        emojiResponse: emojiKey,
        question: lastQuestion,
        response: lastResponse,
        additionalInfo: ''
      };

      if (!feedbackDocId) {
        const feedbackRef = await addDoc(collection(firestore, 'feedback'), feedbackData);
        setFeedbackDocId(feedbackRef.id);
      } else {
        const feedbackRef = doc(firestore, 'feedback', feedbackDocId);
        await updateDoc(feedbackRef, { emojiResponse: emojiKey });
      }
    }
  };

  const handleFeedbackSubmit = async () => {
    if (selectedEmoji && !submitted) {
      const feedbackRef = doc(firestore, 'feedback', feedbackDocId);
      await updateDoc(feedbackRef, {
        additionalInfo: feedbackText.trim(),
      });
      
      setSubmitted(true);
    }
  };

  if (submitted) {
    return <Text mt={2}>¡Gracias por ayudarnos a mejorar el asistente!</Text>;
  }

  return (
    <Box mt={4}>
      <Text mb={2}>Evalúa la respuesta:</Text>
      <HStack spacing={5}>
        {Object.entries(emojis).map(([key, emoji]) => (
          <Button
            key={key}
            colorScheme={selectedEmoji === key ? 'blue' : 'gray'}
            onClick={() => handleEmojiClick(key)}
            disabled={submitted}
          >
            {emoji}
          </Button>
        ))}
      </HStack>
      <HStack mt={2}>
        <Input
          flex={1}
          placeholder="Comentarios adicionales (opcional)"
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
          onBlur={handleFeedbackSubmit}
          disabled={submitted}
        />
        <Button
          onClick={handleFeedbackSubmit}
          disabled={selectedEmoji === null || submitted}
        >
          Enviar
        </Button>
      </HStack>
    </Box>
  );
};
export default Feedback;