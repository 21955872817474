// LatestQuestions.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Heading, VStack, Text, Spinner, Grid, GridItem } from '@chakra-ui/react';
import { fetchLastQuestions } from '../firebase.utils';
import { format } from 'date-fns'; 

const LatestQuestions = ({ country }) => {
  const [latestQuestions, setLatestQuestions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const questions = await fetchLastQuestions(country);
        setLatestQuestions(questions);
      } catch (error) {
        console.error('Error fetching latest questions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, [country]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box maxWidth="1200px" mx="auto">
      <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
        {latestQuestions.map(({ id, data }) => (
          <GridItem key={id} p={5} shadow="md" borderWidth="1px" borderRadius="md">
            <VStack align="stretch" spacing={3}>
              <Heading fontSize="lg" as={Link} to={`/question/${id}?country=${country}`} textDecoration="none" _hover={{ textDecoration: 'underline' }}>
                {data.question}
              </Heading>
              <Text color="gray.600" fontSize="sm">
                {format(new Date(data.createdAt.toDate()), 'PPPpp')}
              </Text>
            </VStack>
          </GridItem>
        ))}
      </Grid>
      <Box textAlign="center" mt={8}>
        <Link to={`/questions?country=${country}`}>
          <Text fontSize="md" fontWeight="bold" textDecoration="underline">
            Mostrar más preguntas
          </Text>
        </Link>
      </Box>
    </Box>
  );
};

export default LatestQuestions;