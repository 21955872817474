import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDoc, doc, setDoc, where, query, orderBy, limit, startAfter, getDocs, updateDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { getAuth, } from 'firebase/auth';




const firebaseConfig = {
    apiKey: "AIzaSyAtpAddq0ybikyg0iXYi4NyUKPjRAe_qHA",
    authDomain: "safety-bot-fd512.firebaseapp.com",
    projectId: "safety-bot-fd512",
    storageBucket: "safety-bot-fd512.appspot.com",
    messagingSenderId: "758788132894",
    appId: "1:758788132894:web:5b4005275b519a30f49e0f",
    measurementId: "G-PWFXV55TFH"
  };

// Initialize Firebase and Firestore
const appFirebase = initializeApp(firebaseConfig);
const db = getFirestore(appFirebase);
const auth = getAuth(appFirebase);


export const getLibraryQuestions = async (country, lastDoc = null) => {
  try {
    const pageSize = 10;
    let q;

    if (lastDoc) {
      q = query(
        collection(db, 'questions'),
        where('country', '==', country),
        orderBy('createdAt', 'desc'),
        startAfter(lastDoc),
        limit(pageSize)
      );
    } else {
      q = query(
        collection(db, 'questions'),
        where('country', '==', country),
        orderBy('createdAt', 'desc'),
        limit(pageSize)
      );
    }

    const querySnapshot = await getDocs(q);
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      data: querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
      lastDoc: lastVisible, // Use consistent naming (lastDoc instead of lastVisible)
    };
  } catch (error) {
    console.error('Error fetching library forms:', error);
    throw error;
  }
};


export const searchLibraryQuestions = async (searchTerm, lastDocSnapshot = null, country) => {
    try {
      const searchLimit = 50;
      // Ensure we search for the exact matching case used in DB
      const searchValue = searchTerm.trim(); 
  
      let searchQuery = query(collection(db, 'questions'),
        orderBy('question'),
        where('country', '==', country),
        where('question', '>=', searchValue),
        where('question', '<=', searchValue + '\uf8ff'),
        limit(searchLimit)
      );
  
      if (lastDocSnapshot) {
        searchQuery = query(searchQuery, startAfter(lastDocSnapshot));
      }
  
      const querySnapshot = await getDocs(searchQuery);
  
      if (querySnapshot.empty) {
        console.log('No matching documents.');
        return { forms: [], lastVisible: null };
      }
  
      const questions = querySnapshot.docs.map(doc => ({ id: doc.id, data: doc.data() }));
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  
      return {
        questions,
        lastVisible
      };
    } catch (error) {
      console.error('Error searching library forms:', error);
      throw error;
    }
  };
  
  export const fetchLastQuestions = async (country) => {
    const formsRef = collection(db, 'questions');
    const queryLastForms = query(formsRef,  where('country', '==', country), orderBy('createdAt', 'desc'), limit(6));
  
    const querySnapshot = await getDocs(queryLastForms);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
  };
  
  
  export const getQuestionById = async (formId) => {
    try {
      const docRef = doc(db, 'questions', formId);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        return { id: docSnap.id, ...docSnap.data() };
      } else {
        console.error("No such document!");
      }
    } catch (error) {
      console.error('Error getting form:', error);
      throw error;
    }
  };
  