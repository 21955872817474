// components/MessageList.js
import React, { useRef, useEffect, useState } from 'react';
import {
  VStack,
  Flex,
  Box,
  Text,
  Center,
  Image,
  Heading,
  HStack,
  SimpleGrid,
  Button,
  Container,
  useBreakpointValue,
  Stack,
  Spinner
} from '@chakra-ui/react';
import { FcEngineering } from 'react-icons/fc';
import ReactMarkdown from 'react-markdown';
import './../App.css';
import { useNavigate } from 'react-router';  
import UserProfileSide from './UserProfileSide';
import Feedback from './Feedback';

const sampleQuestions = [
  { id: 1, text: '¿Qué equipamiento de seguridad es obligatorio?' },
  { id: 2, text: '¿Cuáles son los EPP para trabajos en altura?' },
  { id: 3, text: 'Procedimientos para manejar productos químicos' },
  { id: 4, text: 'Normativas sobre ruido en el trabajo' }
];

function UserWelcomePanel({ user, onNewChat }) {
  return (
    <VStack
      spacing={4}
      p={10}
      pt={100}
      pb={100}
      m={5}
      borderRadius="lg"
      bgColor="yellow.50"
      align="flex-start"
      justify="center"
    >
      <Text>Escribe tu pregunta o comienza con una de estas sugerencias:</Text>
      <SimpleGrid columns={2} spacing={2}>
        {sampleQuestions.map((question) => (
          <Box key={question.id} p={3} bgColor="yellow.300" borderRadius="md" cursor="pointer" onClick={() => {  onNewChat(question.text, undefined); }}>
            <Text textAlign="center">{question.text}</Text>
          </Box>
        ))}
      </SimpleGrid>
    </VStack>
  );
}

const MessageList = ({ messages, isLoading, isAuthenticated, isBotTyping, sendQuestion, user, threads, threadID }) => {
  const bottomRef = useRef(null);
  const headingFontSize = useBreakpointValue({ base: '2xl', md: '4xl' });
  let navigate = useNavigate();
  const sidebarWidth = useBreakpointValue({ base: "100%", md: "30%" }); // Adjust values as needed
  const mainAreaWidth = useBreakpointValue({ base: "100%", md: "70%" })

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [messages]); 


  console.log('isTyping', isBotTyping)
  if (isLoading && messages.length === 0) {
    return (
      <Center h="full">
        <Spinner size="xl" />
      </Center>
    );
  }

  const onNewChat = (initialQuestion, threadId) => {
    if (threadId) {
      navigate(`/?threadID=${threadId}`);
    } else {
      sendQuestion(initialQuestion);
    }
  };

  
  const renderMessages = () => {
    return messages.map((message, index) => {
      const isLastMessage = index === messages.length - 1;

      return (
      <Flex
        key={index}
        direction="column"
        align={message.role === 'user' ? 'flex-end' : 'flex-start'}
        mb={4}
        minWidth="70%" // Ensures that the Flex container respects minimum width
      >
        <Box
          bg={message.role === 'user' ? 'blue.100' : 'green.100'}
          p={8}
          borderRadius="md"
          maxW="80%"
          w="auto" // Allows the width to grow with content but won't exceed maxW
          alignSelf={message.role === 'user' ? 'flex-end' : 'flex-start'}
        >
          <ReactMarkdown>{message.content}</ReactMarkdown>
        </Box>
        {isLastMessage && message.role === 'assistant' && (
          <Feedback
          threadID={threadID}
          lastQuestion={messages[messages.length - 2]?.content} // Assuming the second to last message is the question
          lastResponse={messages[messages.length - 1]?.content} // Assuming the last message is the response
        />
        )}
      </Flex>
      );

      });
  };


  return (
    <HStack spacing={0} w="full" h="full" align="stretch">
        <Box w={{ base: "100%", md: sidebarWidth }} display={{ base: "none", md: "block" }}>
        <UserProfileSide onNewChat={onNewChat} threads={threads} />
      </Box>
      <VStack w={mainAreaWidth} spacing={4} align="flex-start" marginX="10" pb={100} mt={20}>
      <Box overflowY="auto" maxH="80vh" p={5} width="full" className={!isAuthenticated ? 'blurred' : ''}>
      {messages.length == 0 && <UserWelcomePanel user={user} onNewChat={onNewChat} />}
        {messages.length > 0 && renderMessages()}
        <div ref={bottomRef} />
        <VStack spacing={4} align="flex-start">
      {/* Render messages here... */}
      {isBotTyping && (<VStack spacing={4} align="center" justify="center" p={4} w="full">
  <Text fontSize="lg" textAlign="center" px={4}>
  Estoy en proceso de buscar la información que necesitas. Este procedimiento puede llevar unos minutos, así que agradecería tu paciencia. ¡Pronto estaré de vuelta con la respuesta!
  </Text>
  <Box position="relative" width="full" display="flex" justifyContent="center">
    <video
      preload="metadata"
      width="256"
      height="192"
      muted
      loop
      autoPlay
      playsInline
    >
      <source src="loading-video.mp4" type="video/mp4" />
    </video>
  </Box>
  <Text fontSize="sm" color="gray.500" textAlign="center" px={4}>
    TrabajoSeguro combina la potencia de GPT-4 Turbo, el conocimiento de expertos en seguridad laboral y miles de documentos oficiales para mantenerte al día con la normativa chilena y crear un entorno de trabajo seguro.
  </Text>
</VStack>)} {/* Show this at the end of your messages */}
    </VStack>
      </Box>
    </VStack>
    </HStack>
  );
};

export default MessageList;