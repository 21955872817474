import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  Box,
  Flex,
  Heading,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Image,
  Spacer,
  extendTheme,
  IconButton,
  Link,
  Text,
  useToast,
  Avatar,
  useDisclosure
} from '@chakra-ui/react';
import {
  BrowserRouter as Router,
  Routes,
  useLocation,
  Link as RouterLink,
  Route,
} from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp, orderBy, query, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithEmailLink, isSignInWithEmailLink, sendSignInLinkToEmail, signOut,  } from 'firebase/auth';
import SignUpSignInModal from './components/SignUpSignInModal';
import CountryChat from './components/CountryChat';
import mixpanel from 'mixpanel-browser';
import Library from './components/Library';
import QuestionLanding from './components/QuestionLanding';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Header from './components/Header';
import LatestQuestions from './components/LatestQuestions';
import { set } from 'date-fns';

// ...Firebase initialization...
const firebaseConfig = {
  apiKey: "AIzaSyAtpAddq0ybikyg0iXYi4NyUKPjRAe_qHA",
  authDomain: "safety-bot-fd512.firebaseapp.com",
  projectId: "safety-bot-fd512",
  storageBucket: "safety-bot-fd512.appspot.com",
  messagingSenderId: "758788132894",
  appId: "1:758788132894:web:5b4005275b519a30f49e0f",
  measurementId: "G-PWFXV55TFH"
};
// Firebase initialization
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();
mixpanel.init('00e6c823bb6ae630fea3017a862c26f4', {debug: true, track_pageview: true, persistence: 'localStorage'});

function App() {
  const [user, setUser] = useState(null);
  const [currentCountry, setCurrentCountry] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [apiKeyToken, setApiKeyToken] = useState(null);
  const toast = useToast();
  const [country, setCountry] = useState(localStorage.getItem('country') || 'chile');
  const [assisstantId, setAssistantId] = useState( localStorage.getItem('country') == 'mexico' ? 'asst_8LTfJTDUEsZun0SRsmnYAcgX' : 'asst_bYplpA3DiCqcs105DRSSsyWP');
  
  const setCountryAction = (country) => {
    console.log('country', country)
    setCountry(country);
    localStorage.setItem('country', country);
    if(country == "mexico"){
      setAssistantId('asst_8LTfJTDUEsZun0SRsmnYAcgX');
    }
    else{
      setAssistantId('asst_bYplpA3DiCqcs105DRSSsyWP');
    }
  };

  const theme = extendTheme({
    colors: {
      brand: {
        headerBg: "#ffb200", // Color de fondo amarillo para el encabezado
        headerTextPrimary: "#00284e", // Color azul oscuro para el texto 'TRABAJO'
        headerTextSecondary: "#FFFFFF", // Color blanco para el texto 'Seguro'
        footerBg: "#00284e", // Color azul oscuro para el fondo del pie de página
        footerText: "#FFFFFF", // Color blanco para texto del pie de página
        // ...otros colores según sea necesario
      },
    },
    // ...configura otras partes del tema aquí si es necesario
  });

  useEffect(() => {
    // Attach the auth listener to handle user authentication state
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user); 
      if(user) {
        mixpanel.identify(user.uid);
      }
      setIsAuthenticated(!!user);
      if (user) {
        // If authenticated, the user might have an apiKeyToken
        const token = user.getIdToken(); // Get the Firebase ID token
        setApiKeyToken(token);
        console.log(user);
      }
    });

    const handleEmailLinkSignIn = async (email) => {
      try {
        await signInWithEmailLink(auth, email, window.location.href);
        // Handle successful sign-in
        window.localStorage.removeItem('emailForSignIn');
        setIsAuthenticated(true);
        toast({
          title: 'Inicio de sesión exitoso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        // TODO: Redirect the user to main page or dashboard
      } catch (error) {
        console.error(error);
        toast({
          title: 'Error al iniciar sesión.',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };

    // Check for email link sign-in
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      if (email) {
        handleEmailLinkSignIn(email);
      } else {
        // Fallback if email is not in local storage (e.g., user changed device or cleared storage)
        email = window.prompt('Por favor, ingresa tu correo electrónico para confirmar');
        if (email) handleEmailLinkSignIn(email);
      }
    }

    // Unsubscribe from the auth listener when component unmounts
    return () => unsubscribe();
  }, []);




  const handleSignInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, new GoogleAuthProvider());
      if (result.user) {
        setUser(result.user);
        mixpanel.identify(result.user.uid);
        setIsAuthenticated(true);
        onClose(); // Close the SignUpSignInModal

        toast({
          title: 'Ingreso exitoso.',
          description: `Bienvenido ${result.user.displayName}!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Authentication failed:", error);

      toast({
        title: 'Error al ingresar.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSignUpWithEmail = async () => {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
  
    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
      onClose();
      toast({
        title: 'Revisa tu correo.',
        description: 'Te hemos enviado un enlace de inicio de sesión a tu email. Por favor, revisa tu bandeja de entrada.',
        status: 'info',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error(error.message);
      toast({
        title: 'Error al enviar el enlace de inicio de sesión.',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleLogout = async () => {
    await signOut(auth);
    setUser(null); // Reset user state to null after logout
    setIsAuthenticated(false);
  };

  const countries = [
    { name: 'Chile', path: '/chile', assistantId: 'asst_bYplpA3DiCqcs105DRSSsyWP' },
    { name: 'Mexico', path: '/mexico', assistantId: 'asst_bYplpA3DiCqcs105DRSSsyWP' },
    { name: 'Colombia', path: '/colombia', assistantId: 'asst_bYplpA3DiCqcs105DRSSsyWP' },
    { name: 'Peru', path: '/peru', assistantId: 'asst_bYplpA3DiCqcs105DRSSsyWP' },
  ];


// This function updates when a country is selected from the menu
/* const handleCountrySelect = (countryPath) => {
  localStorage.setItem('currentCountry', countryPath);
  setCurrentCountry(countryPath);
}; */

console.log('user on app', user);
console.log('country', country);
console.log('assisstantId', assisstantId);

  return (
    <Router>
      <ChakraProvider theme={theme} >
      <Flex
          as="header"
          position="fixed"
          top={0}
          left={0}
          right={0}
          align="center"
          justify="space-between"
          p={4}
          bg="brand.headerBg"
          color="brand.headerTextSecondary"
          borderBottom="1px"
          borderColor="gray.200"
          boxShadow="sm"
          zIndex="banner"
        >
          <Header setCountry={setCountryAction} country={country} />
          <Spacer />
          <Spacer />
        <Flex
        as="header"
        align="center"
        justify="end"
        p={4}
      >
        <Spacer />
      </Flex>
          <Flex alignItems="center">
           
            {user ? (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<Avatar size="sm" src={user.photoURL} />}
              variant="outline"
              size="sm"
              mr={2}
            />
            <MenuList>
              <MenuItem  bg="brand.headerBg" onClick={handleLogout}>Cerrar sesión</MenuItem>
            </MenuList>
          </Menu>
        ) : ''}
          </Flex>
        </Flex>





      { user ? (<Box mt="4rem">
          <Routes>
          <Route
                key="library"
                path="/questions"
                element={<Library isAuthenticated={isAuthenticated} currentUser={user} country={country} />}
              />
               <Route
                key="library"
                path="/question/:formId"
                element={<QuestionLanding isAuthenticated={isAuthenticated} currentUser={user} country={country} />}
              />
            <Route
                key="Chile"
                path="/"
                element={<CountryChat country={country} assisstantId={assisstantId} isAuthenticated={isAuthenticated} currentUser={user} onOpen={onOpen} />}
              />
          </Routes>
        </Box>) : ''}
        { !user  &&
         (<Routes>
          <Route
                key="library"
                path="/questions"
                element={<Library isAuthenticated={isAuthenticated} currentUser={user} country={country} />}
              />
                      <Route
                key="library"
                path="/question/:formId"
                element={<QuestionLanding isAuthenticated={isAuthenticated} currentUser={user} country={country}  signInWithGoogle={handleSignInWithGoogle} handleSignUpWithEmail={handleSignUpWithEmail}   setEmail={setEmail} />}
              />
         <Route
             key="SignUpSignInModal"
             path="/"
             element={<SignUpSignInModal
              isOpen={isOpen}
              onClose={onClose}
              setEmail={setEmail}
              signInWithGoogle={handleSignInWithGoogle}
              handleSignUpWithEmail={handleSignUpWithEmail}
              isAuthenticated={isAuthenticated}
              country={country}
            />}
           />
       </Routes>)
        }

<Box
      as="footer"
      position="fixed"
      left={0}
      bottom={0}
      width="full"
      bg="brand.footerBg" // Reemplaza con tu variable de color
      color="brand.footerText" // Reemplaza con tu variable de color
      py={4}
      zIndex="overlay" // Asegúrate de que el footer se situe por encima del resto del contenido si es necesario
    >
      <Flex
        justify="space-between"
        align="center"
        direction={{ base: 'column', md: 'row' }}
        px={4}
      >
        {/* Contenido izquierdo */}
        <Flex direction="column" align={{ base: 'center', md: 'start' }} mb={{ base: 4, md: 0 }}>
          <Link href="https://datascope.io/es/" isExternal fontSize="sm">
            Creado por DataScope.io
          </Link>
          <Link href="https://datascope.io/es/terms" isExternal fontSize="sm">
            Términos y condiciones
          </Link>
        </Flex>

        {/* Contenido derecho */}
        <Flex direction="column" align={{ base: 'center', md: 'end' }} display={{ base: 'none', md: 'flex' }}>
          <Text fontSize="sm">Contacto de Soporte</Text>
          <Text fontSize="sm">trabajoseguro.soporte@datascope.io</Text>
        </Flex>
      </Flex>
    </Box>
      </ChakraProvider>
    </Router>
  );
}

export default App;