import React, { useState, useEffect, Suspense } from 'react';
import { Box, VStack, Heading, Button, Modal, Center, Image,
  useToast,
  Container,
  useDisclosure, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody,
Text } from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import { getQuestionById } from '../firebase.utils';
import { Helmet } from 'react-helmet'; // For SEO optimization
import {DownloadIcon, EditIcon} from '@chakra-ui/icons';
import { useNavigate, useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown'; 
import axios from 'axios';
import SEO from './SEO';
import SForm from './SForm';
import { loremIpsum } from "lorem-ipsum";

const backendUrl = process.env.REACT_APP_BACKEND_URL;


const QuestionLanding = ({ currentUser, country, handleSignUpWithEmail, setEmail,  signInWithGoogle   }) => {
  const { formId } = useParams();
  const [form, setForm] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    const fetchForm = async () => {
      const fetchedForm = await getQuestionById(formId);
      console.log(fetchedForm);
      setForm(fetchedForm);
    };
    fetchForm();
  }, [formId]);

  const handleAskQuestionClick = () => {
    // Check if the user is authenticated
    if (!currentUser) {
      toast({
        title: 'Acción requerida',
        description: 'Necesitas iniciar sesión o crear una cuenta para hacer una pregunta.',
        status: 'info',
        duration: 9000,
        isClosable: true,
      });
      onOpen(); // Open sign-up/sign-in modal
    } else {
      navigate('/'); // Navigate to question form
    }
  };

  const renderResponseText = (response) => {
  
      const halfwayPoint = Math.floor(response.length / 2);
      const firstHalf = response.substring(0, halfwayPoint);
      let blurredHalf = response.substring(halfwayPoint);
      if(!currentUser){
        blurredHalf = loremIpsum({
          count: blurredHalf.split(' ').length, // Number of "words" to generate.
          format: "plain", // "plain" or "html"
          units: "words" // Generate words, sentences, or paragraphs.
        });
      }

      return (
        <>
          <ReactMarkdown>{firstHalf}</ReactMarkdown>
          <Box filter={ currentUser ? '' : 'blur(4px)'} onClick={onOpen}>
            <ReactMarkdown>{blurredHalf}</ReactMarkdown>
            <VStack
      w={{ base: 'full', md: '100%' }}
      mt={10}
      spacing={6}
      pt={20}
      align="start"
      justify="center"
      p={{ base: 4, md: 10 }}
      bg="#ffebc2"
      color="#00284e"
    >
      <Heading size="lg" fontWeight="bold">
        Combinamos la potencia de <Text as="span" fontWeight="extrabold">GPT-4 Turbo</Text> {`con el conocimiento de expertos en seguridad laboral y miles de documentos oficiales para mantenerte al día con la normativa ${country === "chile" ? "Chilena" : "Mexicana"} y crear un entorno de trabajo seguro.`}
      </Heading>
      <Center w="full">
        <Button
          size="lg"
          colorScheme="blue"
          onClick={handleAskQuestionClick} 
        >
          Preguntar Gratis
        </Button>
      </Center>
      <Image src="../background-img.png" w={{ base: '60%' }} alignSelf="center" alt="TrabajoSeguro Logo" />
    </VStack>
          </Box>
          <Center my={4}>
            <Button colorScheme="blue" onClick={onOpen}>Regístrate para ver la respuesta completa</Button>
          </Center>
        </>
      );
  
  };

  if (!form) {
    return <Box>Loading...</Box>;
  }

  return (
    <Container maxW="container.md" marginTop={20} mb={40} >
      <SEO title={'TrabajoSeguro AI | ' + form.question} description={form.question + ' | ' + form.response} />
      <Button as={Link} to="/questions" size="xs" mb={4} mt={10}>
        Ver todas las preguntas
      </Button>
      <VStack spacing={4} mt={5} align="stretch">
        <Heading>{form.question}</Heading>
        {renderResponseText(form.response)}
      </VStack>
      <Center w="full" my={10}>
      </Center>
      {/* Registration/SignIn Modal */}
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
          <Heading size="lg" color="#0d4266" mb={4}>
            Te damos la bienvenida a <Text as="span" color="#ffb200">Trabajo</Text><Text as="span">Seguro</Text>
          </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text mb={10}> Combinamos la potencia de <Text as="span" fontWeight="extrabold">GPT-4 Turbo</Text> {`con el conocimiento de expertos en seguridad laboral y miles de documentos oficiales para mantenerte al día con la normativa ${country == "chile" ? "Chilena" : "Mexicana"} y crear un entorno de trabajo seguro.`} </Text>
          <SForm  handleSignUpWithEmail={handleSignUpWithEmail} setEmail={setEmail} signInWithGoogle={signInWithGoogle} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default QuestionLanding;