import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box,
  Input,
  Button,
  VStack,
  useDisclosure,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  HStack,
} from '@chakra-ui/react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, serverTimestamp, orderBy,  getDoc,  limit, query, onSnapshot, where } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signInWithPopup, GoogleAuthProvider, signInWithEmailLink, isSignInWithEmailLink, sendSignInLinkToEmail, signOut } from 'firebase/auth';
import { BrowserRouter as Router, Route, Switch, Link as LinkRouter, useLocation } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { doc, setDoc } from 'firebase/firestore';
import UserProfileSide from './UserProfileSide';
import mixpanel from 'mixpanel-browser';

import MessageList from './MessageList';
import { useNavigate } from 'react-router';  

import './../App.css';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtpAddq0ybikyg0iXYi4NyUKPjRAe_qHA",
  authDomain: "safety-bot-fd512.firebaseapp.com",
  projectId: "safety-bot-fd512",
  storageBucket: "safety-bot-fd512.appspot.com",
  messagingSenderId: "758788132894",
  appId: "1:758788132894:web:5b4005275b519a30f49e0f",
  measurementId: "G-PWFXV55TFH"
};
// Firebase initialization
const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth();

console.log('config env url', process.env.REACT_APP_BACKEND_URL);

const ASSISTANT_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}runOpenAIAssistant`;
const STATUS_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}checkStatus`;

function CountryChat({ onOpen, currentUser, isAuthenticated, assisstantId, country }) {
  const [question, setQuestion] = useState('');
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(currentUser);
  const [currentCountry, setCurrentCountry] = useState('CL');
  const location = useLocation();
  const [threadID, setThreadID] = useState('');
  const [runID, setRunID] = useState('');
  const [isBotTyping, setIsBotTyping] = useState(false);
  let navigate = useNavigate();
  const [threads, setThreads] = useState([]);
  const { isOpen, onOpen: onSidebarOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const queryParams = new URLSearchParams(location.search);
  const intervalIdRef = useRef(null);
  
    console.log('currentUser', currentUser);

    useEffect(() => {
        // Check URL for threadID and set it if present
        const urlThreadId = queryParams.get('threadID');
        setIsBotTyping(false);
        setIsLoading(false);
        if (urlThreadId && isAuthenticated && urlThreadId !== '' && urlThreadId !== 'null') {
          setThreadID(urlThreadId);
          if(intervalIdRef.current !== null) {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
          }

          //get threadID from firestore
          console.log('threadID', urlThreadId);
        const threadRef = doc(firestore, 'users', String(currentUser.uid), 'threads', urlThreadId);
        getDoc(threadRef).then((doc) => {
            if (doc.exists) {
                console.log('Document data:', doc.data());
                setRunID(doc.data().runID);
                console.log('set runID', doc.data().runID );
                if(doc.data().runID !== '' && doc.data().runID !== 'null' && doc.data().runID !== null) {
                    checkResponseStatus(urlThreadId, doc.data().runID);
                }
            } else {
                // doc.data() will be undefined in this case
                console.log('No such document!');
            }
        }).catch((error) => {
            console.log('Error getting document:', error);
        });

        }
      }, [location, isAuthenticated]);


      useEffect(() => {
        if (isAuthenticated && currentUser) {
          // Define the Firestore query with limit 10, ordered by createdAt
          const threadsQuery = query(
            collection(firestore, 'users', currentUser.uid, 'threads'), 
            where('country', '==', country), 
            orderBy('createdAt', 'desc'), 
            limit(10)
          );
    
          // Subscribe to threads collection updates
          const unsubscribe = onSnapshot(threadsQuery, (querySnapshot) => {
            const fetchedThreads = querySnapshot.docs.map(doc => {
              // Combine the Firestore doc id and doc data
              return {
                id: doc.id,
                ...doc.data(),
              };
            });
            setThreads(fetchedThreads); // Update the threads state with the new data
          });
    
          // Clean up: Unsubscribe from the Firestore updates when the component unmounts or currentUser changes
          return () => unsubscribe();
        }
      }, [isAuthenticated, currentUser, firestore]);

  useEffect(() => {
    if (threadID) {
        console.log('threadID', threadID);
      setIsLoading(true);
      const q = query(
        collection(firestore, 'users', String(currentUser.uid), 'threads', threadID, 'messages'),
        orderBy('createdAt', 'asc')
      );
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const newMessages = querySnapshot.docs.map((doc) => ({
          role: doc.data().role,
          content: doc.data().content,
        }));
        console.log('new message', newMessages);
        setMessages(newMessages);
      });

      if (!!runID && runID !== 'undefined') {
        setIsLoading(true);
        checkResponseStatus(threadID, runID);
      }
      else {
        setIsLoading(false); // Not loading if no runID is present
      }
  
      return () => unsubscribe();
    }

  }, [firestore, threadID]);

  const saveMessage = async (role, question, threadID) => {
    console.log('save message :', question);
    const messagesRef = collection(firestore, 'users', String(currentUser.uid), 'threads', threadID, 'messages');
    // Add user message to Firestore
    await addDoc(messagesRef, {
      role: role,
      content: question,
      createdAt: serverTimestamp(),
      uid: String((currentUser || {}).uid),
      userName: String((currentUser || {}).displayName)
    });
  };


  const onNewChat = (initialQuestion, threadId) => {
    if (threadId) {
      navigate(`/?threadID=${threadId}`);
    } else {
      sendQuestion(initialQuestion);
    }
  };

  const sendQuestion = async (question) => {
    console.log('send question :', question);
    mixpanel.track('New Question', { question });
    if(question === ''){
        setThreadID('');
        setRunID('');
        setMessages([]);
        return;
    }
    setMessages([...messages, { role: 'user', content: question, createdAt: new Date(), role: 'user' }]);
    setIsBotTyping(true); 
    setIsLoading(true);
    if (!isAuthenticated) {
      onOpen();
      setIsLoading(false);
    }
    try {
      const requestBody = threadID
        ? { message: question, thread_id: threadID, assisstantId: assisstantId, country: country }
        : { message: question, assisstantId: assisstantId, country: country};
      
      const runResponse = await axios.post(ASSISTANT_ENDPOINT, requestBody);
      
      if (runResponse.data && runResponse.data.thread_id && runResponse.data.id) {
        console.log('response thread processing', runResponse.data.id);

        saveMessage('user',question , runResponse.data.thread_id);
        const threadRef = doc(firestore, 'users', currentUser.uid, 'threads', runResponse.data.thread_id);
        if (!threadID) {
            await setDoc(threadRef, {
                createdAt: serverTimestamp(),
                country: country,
                initialQuestion: question,
                runID: runResponse.data.id,
              }, { merge: true});
          console.log('set run ID after request', runResponse.data.id);
          setRunID(runResponse.data.id);
          setThreadID(runResponse.data.thread_id);
        }
        else{
            await setDoc(threadRef, {
                updatedAt: serverTimestamp(),
                runID: runResponse.data.id,
              }, { merge: true});
            console.log('set run ID after request', runResponse.data.id);
            setRunID(runResponse.data.id);
            setThreadID(runResponse.data.thread_id);

        }
        if (runResponse.data.id) {
          checkResponseStatus(runResponse.data.thread_id, runResponse.data.id);
        }
        navigate(`/?threadID=${runResponse.data.thread_id}`);
      }
      
      setQuestion(''); // Clear input field after sending the question
    } catch (error) {
      console.error('Error sending question:', error);
    } finally {
      //setIsLoading(false);
    }
  };

  const checkResponseStatus = async (threadID, runID) => {
    if (intervalIdRef.current !== null) {
        return; // Do nothing if an interval is already running
      }
    setIsBotTyping(true); 
    intervalIdRef.current  = setInterval(async () => {
      try {
        const statusResponse = await axios.get(`${STATUS_ENDPOINT}?threadID=${threadID}&runID=${runID}`);

        if (statusResponse.data && statusResponse.data.data) {
          let responsesData = [];
          clearInterval(intervalIdRef.current);
          for (let i = 0; i < statusResponse.data.data.length; i++) {
           if(statusResponse.data.data[i].role != 'assistant'){
            break;
           }
           else{
            responsesData.unshift(statusResponse.data.data[i].content[0].text.value);
           }
        
          }

            const assistantMessage = {
              role: 'assistant',
              content: responsesData.join(' '),
            };
            console.log('assistantMessage:', assistantMessage);
            saveMessage('assistant', assistantMessage.content, threadID);

      
          setIsLoading(false);
          setIsBotTyping(false);
          intervalIdRef.current = null;
          const threadRef = doc(firestore, 'users', currentUser.uid, 'threads', threadID);
          //get thread response attribute from firestore
          
          let response = undefined;
          const threadSnap = await getDoc(threadRef);
          if (threadSnap.exists()) {
            response = threadSnap.data().response;
          }
          
          setRunID('');
          await setDoc(threadRef, {
            runID: '',
            response: response || responsesData.join(' ')
          }, { merge: true});
        }
      } catch (error) {
        clearInterval(intervalIdRef.current);
        console.error('Error checking status:', error);
      }
    }, 3000);
  };

   const firstVisit = messages.length === 0;

  return (
      <>
        {/* Collapsible menu for mobile */}
         <IconButton
        ref={btnRef}
         icon={<HamburgerIcon />}
         onClick={onSidebarOpen}
         display={{ base: 'flex', md: 'none' }}
         position="fixed"
         top="1rem"
         left="1rem"
         zIndex="banner"
       />

       <Drawer
         isOpen={isOpen}
         placement="left"
         onClose={onClose}
         finalFocusRef={btnRef}
       >
         <DrawerOverlay />
         <DrawerContent>
         </DrawerContent>
       </Drawer>

      <Box position="relative">
        
        <VStack spacing={4} align="stretch" >
        <Box pt="20px"  overflowY="auto" mt={25} mb={100} >
        {/* Use MessageList for rendering messages */}
        <MessageList messages={messages} isLoading={isLoading} isAuthenticated={isAuthenticated} isBotTyping={isBotTyping} onOpen={onOpen} sendQuestion={sendQuestion} threads={threads} threadID={threadID}  />
      </Box>
      
      <Box
            position="fixed"
            bottom={{ base: "20", md: "80px" }}  // Adjust for mobile view
            left="0"
            right="0"
            padding={4}
            boxShadow="sm"
            bg="white"
            zIndex="sticky"
          >
      <HStack spacing={4}>
        <Input
          flex="1"
          variant="filled"
          placeholder={`Pregunta cualquier cosa sobre la seguridad en ${country.substring(0, 1).toUpperCase()}${country.substring(1)}.`}
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && question.trim() && !isLoading) {
              sendQuestion(question);
            }
          }}
          isDisabled={isLoading}
        />
        <Button
          colorScheme="blue"
          onClick={() => { sendQuestion(question); }}
          isLoading={isLoading}
          loadingText="Buscando información..."
          disabled={!question || isLoading}
        >
          Preguntar
        </Button>
      </HStack>
    </Box>
        </VStack>
      </Box>
      </>
  );
}

export default CountryChat;