import React, { useEffect } from 'react';
import { Flex, Heading, Menu, MenuButton, MenuList, IconButton, Image, Box, MenuItem, Button } from '@chakra-ui/react';
import {
    useLocation,
    Link as RouterLink,
  } from 'react-router-dom';

  import { ChevronDownIcon } from '@chakra-ui/icons';
function Header({setCountry, country}) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const countryParam = searchParams.get('country');
  if(!!countryParam  && countryParam !== country) {
    setCountry(countryParam);
  }
  const countryNames = {
    mexico: 'México',
    chile: 'Chile'
  };

  const countryFlags = {
    mexico: '/mexico-flag.png',
    chile: '/chile-flag.png'
  };
    
  return (
    <Flex align="center" mr={5} pl={20}>
            {/* ...otros elementos del encabezado... */}
            <Heading as="h1" size="lg" letterSpacing="-.1rem" color="brand.headerTextPrimary">
            <RouterLink to="/">TRABAJO</RouterLink>
            </Heading>
            <Heading as="h1" size="lg" letterSpacing="-.1rem" ml={1}>
            <RouterLink to="/">Seguro</RouterLink>
            </Heading>
            <Menu>
        <MenuButton as={Button} variant="unstyled" ml={5}>
          <Flex align="center">
          <Heading as="h3" size="lg" letterSpacing=".2rem" ml={1}>
              {countryNames[country.toLowerCase()]}
            </Heading>
            <Image src={countryFlags[country.toLowerCase()]} boxSize="30px" mr={2} />
            <ChevronDownIcon />
          </Flex>
        </MenuButton>
        <MenuList bg="brand.headerBg">
        <MenuItem as='a' href="/?country=mexico" bg="brand.headerBg">
  <Image src={countryFlags.mexico} boxSize="30px" mr={2} />
  México
</MenuItem>
<MenuItem as='a' href="/?country=chile" bg="brand.headerBg">
  <Image src={countryFlags.chile} boxSize="30px" mr={2} />
  Chile
</MenuItem>
          {/* Add more countries here */}
        </MenuList>
      </Menu>
          </Flex>
  );


}

export default Header;