// components/SignUpSignInModal.js
import React from 'react';
import {
    
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Box,
  HStack,
  Button,
  VStack,
  Image,
  Heading,
  Text,
  Icon,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast
} from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import LatestQuestions from './LatestQuestions';
import SForm from './SForm';
import SEO from './SEO';

export default function SignUpSignInModal({
    country,
    onClose,
    setEmail,
    signInWithGoogle,
    handleSignUpWithEmail,
    isAuthenticated
  }) {
    const cancelRef = React.useRef();
    const toast = useToast();

    // Override the onClose method to prevent modal close if not authenticated
    const closeHandler = () => {
      if (!isAuthenticated) {
        // Alert the user in Spanish
        toast({
            title: 'Registro Necesario',
            description: 'Debes registrarte o iniciar sesión para ver la respuesta a tu pregunta.',
            status: 'warning',
            duration: 4000,
            isClosable: true,
            position: 'top',
          });
      } else {
        onClose(); // Close the modal if the user is authenticated
      }
    };

    const structuredData = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "Trabajo Seguro AI",
      "url": "https://trabajoseguro.guru",
      "logo": "https://trabajoseguro.guru/logo.png",
      "description": "Combinamos la potencia de GPT-4 Turbo con el conocimiento de expertos en seguridad laboral para un entorno de trabajo seguro.",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://trabajoseguro.guru/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      },
      "address": {
        "@type": "PostalAddress",
        "addressCountry": country === "chile" ? "CL" : "MX"
      }
    };
    
  return (
    <>
    <SEO />

<Flex
      direction={{ base: 'column', md: 'row' }}
      align={{ base: 'start', md: 'center' }}
      justify="center"
      pt={20}
      h="100vh"
      bg="#ffebc2"
      w="full"
      p={{ base: 4, md: 10 }}
      spacing={0}
    >
      {/* Left side with hero content */}
      <VStack
        w={{ base: 'full', md: '60%' }}
        spacing={6}
        pt={20}
        align="start"
        justify="center"
        p={{ base: 4, md: 10 }}
        bg="#ffebc2"
        color="#00284e"
      >
        <Heading size="lg" fontWeight="bold">
          Combinamos la potencia de <Text as="span" fontWeight="extrabold">GPT-4 Turbo</Text> {`con el conocimiento de expertos en seguridad laboral y miles de documentos oficiales para mantenerte al día con la normativa ${country == "chile" ? "Chilena" : "Mexicana"} y crear un entorno de trabajo seguro.`}
        </Heading>
        <Image src="background-img.png" w={{ base: '60%' }} alignSelf="center" alt="TrabajoSeguro Logo" />
      </VStack>

      {/* Right side with sign-up/sign-in form */}
      <Box
        w={{ base: 'full', md: '40%' }}
        bg="white"
        boxShadow="md"
        rounded="lg"
        align="center"
        justify="center"
        p={{ base: 4, md: 10 }}
        mt={{ base: 4, md: 0 }}
      >
        <VStack spacing={6} w="full" maxW="lg">
          <Heading size="lg" color="#0d4266" mb={4}>
            Te damos la bienvenida a <Text as="span" color="#ffb200">Trabajo</Text><Text as="span">Seguro</Text>
          </Heading>
          <SForm  handleSignUpWithEmail={handleSignUpWithEmail} setEmail={setEmail} signInWithGoogle={signInWithGoogle} />
        </VStack>
      </Box>
    </Flex>
    { 
           <Box mt={20} mb={40} >
        <LatestQuestions country={country} />
        </Box>
        }
      </>
  );
}