import React from 'react';
import { Button, FormControl, FormLabel, Input, Text, Icon } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';

export default function SForm({ handleSignUpWithEmail, setEmail, signInWithGoogle }) {
    return (
      <>
        <FormControl id="email-for-signup">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            placeholder="Ingresa tu correo electrónico"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button mt={4} onClick={handleSignUpWithEmail} colorScheme="yellow" width="full">
            Regístrate con Email
          </Button>
        </FormControl>

        <Button leftIcon={<Icon as={FcGoogle} />} onClick={signInWithGoogle} colorScheme="gray" width="full"  mb={5} mt={2}>
          Inicia sesión con Google
        </Button>

        <Text fontSize="sm" textAlign="center">
          Al registrarte, aceptas nuestros <Text as="span" color="blue.600" textDecoration="underline">Términos de Servicio</Text> y te comprometes a mejorar la seguridad de tu entorno laboral.
        </Text>
      </>
    );
  }