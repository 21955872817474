import {
    Button,
    VStack,
    Heading,
  } from '@chakra-ui/react';

function UserProfileSide({ onNewChat, threads, onClose }) {
    return (
        <VStack width="full" spacing={4} align="flex-start" p={4} bg="white" borderRight="1px" borderColor="gray.200">
        <Heading size="lg" mb={2} >Chats</Heading>
        <Button colorScheme="blue" onClick={() =>  { onNewChat('');  if(onClose ){ onClose(); } }}>Nuevo Chat</Button>
        {threads.map((thread) => (
            <Button 
             key={thread.id} 
             my={1}
            variant="ghost" 
            height="auto"
            minH="min-content"
            justifyContent="start" 
            onClick={() => {
                console.log(thread.id);
                onNewChat(thread.initialQuestion, thread.id);
                if(onClose ){
                    onClose(); // Call the onClose function to close the drawer
                }
            }}
            whiteSpace="normal" // Override default `nowrap` to allow wrapping
            wordBreak="break-word" 
            lineHeight="shorter" // Break word if it's too long for the line
            textAlign="left"  // Allow long words to be able to break and wrap onto the next line
            >
        {thread.title || thread.initialQuestion}
        </Button>
        ))}
      </VStack>
    );
  }

  export default UserProfileSide;